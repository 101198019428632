export const CLEAN_ROOM = {
  CREATE_NEW_RUN: 'CREATE_NEW_RUN',
  CREATE_NEW_RUN_ERROR: 'CREATE_NEW_RUN_ERROR',
  CREATE_NEW_RUN_RESET: 'CREATE_NEW_RUN_RESET',
  CREATE_NEW_RUN_SUCCESS: 'CREATE_NEW_RUN_SUCCESS',
  ADD_QUESTION: 'ADD_QUESTION',
  ADD_QUESTION_ERROR: 'ADD_QUESTION_ERROR',
  ADD_QUESTION_SUCCESS: 'ADD_QUESTION_SUCCESS',
  ADD_CR_USER: 'ADD_CR_USER',
  ADD_CR_USER_ERROR: 'ADD_CR_USER_ERROR',
  ADD_CR_USER_OPEN_MODAL: 'ADD_CR_USER_OPEN_MODAL',
  ADD_CR_USER_SUCCESS: 'ADD_CR_USER_SUCCESS',
  CREATE_CR: 'CREATE_CR',
  CREATE_CR_DATASET: 'CREATE_CR_DATASET',
  CREATE_CR_DATASET_ERROR: 'CREATE_CR_DATASET_ERROR',
  CREATE_CR_DATASET_SUCCESS: 'CREATE_CR_DATASET_SUCCESS',
  CREATE_SCHEDULED_RUN: 'CREATE_SCHEDULED_RUN',
  CREATE_SCHEDULED_RUN_ERROR: 'CREATE_SCHEDULED_RUN_ERROR',
  CREATE_SCHEDULED_RUN_SUCCESS: 'CREATE_SCHEDULED_RUN_SUCCESS',
  DELETE_SCHEDULED_RUN: 'DELETE_SCHEDULED_RUN',
  DELETE_SCHEDULED_RUN_ERROR: 'DELETE_SCHEDULED_RUN_ERROR',
  DELETE_SCHEDULED_RUN_SUCCESS: 'DELETE_SCHEDULED_RUN_SUCCESS',
  UPDATE_CR_DATASET: 'UPDATE_CR_DATASET',
  UPDATE_CR_DATASET_ERROR: 'UPDATE_CR_DATASET_ERROR',
  UPDATE_CR_DATASET_SUCCESS: 'UPDATE_CR_DATASET_SUCCESS',
  UPDATE_CR_QUESTION_DETAILS: 'UPDATE_CR_QUESTION_DETAILS',
  UPDATE_CR_QUESTION_DESCRIPTION_ERROR: 'UPDATE_CR_QUESTION_DESCRIPTION_ERROR',
  UPDATE_CR_QUESTION_DESCRIPTION_SUCCESS: 'UPDATE_CR_QUESTION_DESCRIPTION_SUCCESS',
  UPDATE_CR_QUESTION_DETAILS_V2: 'UPDATE_CR_QUESTION_DETAILS_V2',
  FETCH_AND_CACHE_CR_DATASET: 'FETCH_AND_CACHE_CR_DATASET',
  FETCH_AND_CACHE_CR_DATASET_ERROR: 'FETCH_AND_CACHE_CR_DATASET_ERROR',
  FETCH_AND_CACHE_CR_DATASET_SUCCESS: 'FETCH_AND_CACHE_CR_DATASET_SUCCESS',
  FETCH_CR_DATASET: 'FETCH_CR_DATASET',
  FETCH_CR_DATASET_ERROR: 'FETCH_CR_DATASET_ERROR',
  FETCH_CR_DATASET_SUCCESS: 'FETCH_CR_DATASET_SUCCESS',
  RESET_CR_DATASET_STATE: 'RESET_CR_DATASET_STATE',
  FETCH_CR_QUESTION: 'FETCH_CR_QUESTION',
  FETCH_CR_QUESTION_V2: 'FETCH_CR_QUESTION_V2',
  FETCH_CR_QUESTION_SUCCESS: 'FETCH_CR_QUESTION_SUCCESS',
  FETCH_CR_QUESTION_ERROR: 'FETCH_CR_QUESTION_ERROR',
  FETCH_CR: 'FETCH_CR',
  FETCH_CR_ERROR: 'FETCH_CR_ERROR',
  FETCH_CR_SUCCESS: 'FETCH_CR_SUCCESS',
  FETCH_CR_LIST: 'FETCH_CR_LIST',
  FETCH_CR_LIST_ERROR: 'FETCH_CR_LIST_ERROR',
  FETCH_CR_LIST_SUCCESS: 'FETCH_CR_LIST_SUCCESS',
  FETCH_CR_ORG_USER_LIST: 'FETCH_CR_ORG_USER_LIST',
  FETCH_CR_ORG_USER_LIST_ERROR: 'FETCH_CR_ORG_USER_LIST_ERROR',
  FETCH_CR_ORG_USER_LIST_SUCCESS: 'FETCH_CR_ORG_USER_LIST_SUCCESS',
  FETCH_CR_STAKEHOLDER_LIST: 'FETCH_CR_STAKEHOLDER_LIST',
  FETCH_CR_LIST_FILTERS: 'FETCH_CR_LIST_FILTERS',
  FETCH_CR_LIST_FILTERS_ERROR: 'FETCH_CR_LIST_FILTERS_ERROR',
  FETCH_CR_LIST_FILTERS_SUCCESS: 'FETCH_CR_LIST_FILTERS_SUCCESS',
  FETCH_CR_DATASET_GROUPS: 'FETCH_CR_DATASET_GROUPS',
  FETCH_CR_DATASET_GROUPS_ERROR: 'FETCH_CR_DATASET_GROUPS_ERROR',
  FETCH_CR_DATASET_GROUPS_SUCCESS: 'FETCH_CR_DATASET_GROUPS_SUCCESS',
  FETCH_CR_ALL_QUESTIONS: 'CR/FETCH_CR_ALL_QUESTIONS',
  FETCH_CR_ALL_QUESTIONS_ERROR: 'CR/FETCH_CR_ALL_QUESTIONS_ERROR',
  FETCH_CR_ALL_QUESTIONS_SUCCESS: 'CR/FETCH_CR_ALL_QUESTIONS_SUCCESS',
  FETCH_CR_CURRENT_QUESTIONS: 'FETCH_CR_CURRENT_QUESTIONS',
  FETCH_CR_CURRENT_QUESTIONS_ERROR: 'FETCH_CR_CURRENT_QUESTIONS_ERROR',
  FETCH_CR_CURRENT_QUESTIONS_SUCCESS: 'FETCH_CR_CURRENT_QUESTIONS_SUCCESS',
  FETCH_CR_REPORT_FILTERS: 'FETCH_CR_REPORT_FILTERS',
  FETCH_CR_REPORT_FILTERS_ERROR: 'FETCH_CR_REPORT_FILTERS_ERROR',
  FETCH_CR_REPORT_FILTERS_SUCCESS: 'FETCH_CR_REPORT_FILTERS_SUCCESS',
  FETCH_CR_USERS: 'FETCH_CR_USERS',
  FETCH_CR_USERS_ERROR: 'FETCH_CR_USERS_ERROR',
  FETCH_CR_USERS_SUCCESS: 'FETCH_CR_USERS_SUCCESS',
  FETCH_CR_SUMMARY: 'FETCH_CR_SUMMARY',
  FETCH_CR_SUMMARY_SUCCESS: 'FETCH_CR_SUMMARY_SUCCESS',
  FETCH_CR_SUMMARY_ERROR: 'FETCH_CR_SUMMARY_ERROR',
  FETCH_EXPORTABLE_CR: 'FETCH_EXPORTABLE_CR',
  GET_CR_RUN_REPORT: 'GET_CR_RUN_REPORT',
  GET_CR_RUN_REPORT_ERROR: 'GET_CR_RUN_REPORT_ERROR',
  GET_CR_RUN_REPORT_SUCCESS: 'GET_CR_RUN_REPORT_SUCCESS',
  GET_CR_RUN_TABLE_REPORT: 'GET_CR_RUN_TABLE_REPORT',
  GET_CR_RUN_TABLE_REPORT_SUCCESS: 'GET_CR_RUN_TABLE_REPORT_SUCCESS',
  GET_CR_RUN_TABLE_REPORT_ERROR: 'GET_CR_RUN_TABLE_REPORT_ERROR',
  GET_DP_BUDGET_AND_QUERIES: 'GET_DP_BUDGET_AND_QUERIES',
  GET_DP_BUDGET_AND_QUERIES_SUCCESS: 'GET_DP_BUDGET_AND_QUERIES_SUCCESS',
  GET_DP_BUDGET_AND_QUERIES_ERROR: 'GET_DP_BUDGET_AND_QUERIES_ERROR',
  FETCH_QUESTION_RUN: 'FETCH_QUESTION_RUN',
  FETCH_QUESTION_RUN_SUCCESS: 'FETCH_QUESTION_RUN_SUCCESS',
  FETCH_QUESTION_RUN_ERROR: 'FETCH_QUESTION_RUN_ERROR',
  GET_CR_RUN_FILES: 'GET_CR_RUN_FILES',
  GET_CR_RUN_FILES_ERROR: 'GET_CR_RUN_FILES_ERROR',
  GET_CR_RUN_FILES_SUCCESS: 'GET_CR_RUN_FILES_SUCCESS',
  GET_CR_RUN_FILE_PREVIEW: 'GET_CR_RUN_FILE_PREVIEW',
  GET_CR_RUN_FILE_PREVIEW_ERROR: 'GET_CR_RUN_FILE_PREVIEW_ERROR',
  GET_CR_RUN_FILE_PREVIEW_SUCCESS: 'GET_CR_RUN_FILE_PREVIEW_SUCCESS',
  GET_CR_RUNS: 'GET_CR_RUNS',
  GET_CR_RUNS_ERROR: 'GET_CR_RUNS_ERROR',
  GET_CR_RUNS_SUCCESS: 'GET_CR_RUNS_SUCCESS',
  FETCH_CR_RUNS_FILTERS: 'FETCH_CR_RUNS_FILTERS',
  FETCH_CR_RUNS_FILTERS_SUCCESS: 'FETCH_CR_RUNS_FILTERS_SUCCESS',
  FETCH_CR_RUNS_FILTERS_ERROR: 'FETCH_CR_RUNS_FILTERS_ERROR',
  DELETE_CR_QUESTION_RUN: 'DELETE_CR_QUESTION_RUN',
  DELETE_CR_QUESTION_RUN_ERROR: 'DELETE_CR_QUESTION_RUN_ERROR',
  DELETE_CR_QUESTION_RUN_SUCCESS: 'DELETE_CR_QUESTION_RUN_SUCCESS',
  REMOVE_CR_USER: 'REMOVE_CR_USER',
  REMOVE_CR_USER_ERROR: 'REMOVE_CR_USER_ERROR',
  REMOVE_CR_USER_SUCCESS: 'REMOVE_CR_USER_SUCCESS',
  FETCH_CR_QUESTION_DATASETS: 'FETCH_CR_QUESTION_DATASETS',
  FETCH_CR_QUESTION_DATASETS_ERROR: 'FETCH_CR_QUESTION_DATASETS_ERROR',
  FETCH_CR_QUESTION_DATASETS_SUCCESS: 'FETCH_CR_QUESTION_DATASETS_SUCCESS',
  RESET_CR_QUESTION_DATASETS_STATE: 'RESET_CR_QUESTION_DATASETS_STATE',
  FETCH_CR_QUESTION_DATA_OPTIONS: 'FETCH_CR_QUESTION_DATA_OPTIONS',
  FETCH_CR_QUESTION_DATA_OPTIONS_ERROR: 'FETCH_CR_QUESTION_DATA_OPTIONS_ERROR',
  FETCH_CR_QUESTION_DATA_OPTIONS_SUCCESS: 'FETCH_CR_QUESTION_DATA_OPTIONS_SUCCESS',
  FETCH_QUESTION_DETAILS: 'CR/FETCH_QUESTION_DETAILS',
  FETCH_QUESTION_DETAILS_ERROR: 'CR/FETCH_QUESTION_DETAILS_ERROR',
  FETCH_QUESTION_DETAILS_SUCCESS: 'CR/FETCH_QUESTION_DETAILS_SUCCESS',
  FETCH_SCHEDULED_RUN: 'FETCH_SCHEDULED_RUN',
  FETCH_SCHEDULED_RUN_ERROR: 'FETCH_SCHEDULED_RUN_ERROR',
  FETCH_SCHEDULED_RUN_SUCCESS: 'FETCH_SCHEDULED_RUN_SUCCESS',
  FETCH_SCHEDULED_RUNS: 'FETCH_SCHEDULED_RUNS',
  FETCH_SCHEDULED_RUNS_ERROR: 'FETCH_SCHEDULED_RUNS_ERROR',
  FETCH_SCHEDULED_RUNS_SUCCESS: 'FETCH_SCHEDULED_RUNS_SUCCESS',
  UPDATE_SCHEDULED_RUN_STATUS: 'UPDATE_SCHEDULED_RUN_STATUS',
  UPDATE_SCHEDULED_RUN_STATUS_ERROR: 'UPDATE_SCHEDULED_RUN_STATUS_ERROR',
  UPDATE_SCHEDULED_RUN_STATUS_SUCCESS: 'UPDATE_SCHEDULED_RUN_STATUS_SUCCESS',
  RESET_REPORTS: 'CR/RESET_REPORTS',
  SAVE_CR: 'SAVE_CR',
  SAVE_CR_ERROR: 'SAVE_CR_ERROR',
  SAVE_CR_SUCCESS: 'SAVE_CR_SUCCESS',
  RESET_CR: 'RESET_CR',
  UPDATE_CR_STATUS: 'UPDATE_CR_STATUS',
  UPDATE_CR_STATUS_ERROR: 'UPDATE_CR_STATUS_ERROR',
  UPDATE_CR_USER: 'UPDATE_CR_USER',
  UPDATE_CR_USER_ERROR: 'UPDATE_CR_USER_ERROR',
  UPDATE_CR_USER_SUCCESS: 'UPDATE_CR_USER_SUCCESS',
  UPDATE_CR_QUESTION_STATUS: 'UPDATE_CR_QUESTION_STATUS',
  UPDATE_CR_QUESTION_STATUS_ERROR: 'UPDATE_CR_QUESTION_STATUS_ERROR',
  UPDATE_CR_QUESTION_STATUS_SUCCESS: 'UPDATE_CR_QUESTION_STATUS_SUCCESS',
  UPDATE_CR_QUESTION_DATASETS: 'UPDATE_CR_QUESTION_DATASETS',
  UPDATE_CR_QUESTION_DATASETS_ERROR: 'UPDATE_CR_QUESTION_DATASETS_ERROR',
  UPDATE_CR_QUESTION_DATASETS_SUCCESS: 'UPDATE_CR_QUESTION_DATASETS_SUCCESS',
  VERIFY_PARTNER_KEY: 'VERIFY_PARTNER_KEY',
  VERIFY_PARTNER_KEY_SUCCESS: 'VERIFY_PARTNER_KEY_SUCCESS',
  VERIFY_PARTNER_KEY_ERROR: 'VERIFY_PARTNER_KEY_ERROR',
  VERIFY_PARTNER_EMAIL: 'VERIFY_PARTNER_EMAIL',
  VERIFY_PARTNER_EMAIL_SUCCESS: 'VERIFY_PARTNER_EMAIL_SUCCESS',
  VERIFY_PARTNER_EMAIL_ERROR: 'VERIFY_PARTNER_EMAIL_ERROR',
  FETCH_CR_RUN_DETAILS: 'FETCH_CR_RUN_DETAILS',
  FETCH_CR_RUN_DETAILS_SUCCESS: 'FETCH_CR_RUN_DETAILS_SUCCESS',
  FETCH_CR_RUN_DETAILS_ERROR: 'FETCH_CR_RUN_DETAILS_ERROR',
  SET_ACTIVE_CLEAN_ROOM: 'SET_ACTIVE_CLEAN_ROOM',
};
